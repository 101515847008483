/* eslint-disable */
import React from 'react'
import { graphql } from 'gatsby'
import Gitalk from 'gitalk'

import Layout from '../components/layout'
import SEO from '../components/seo'

import 'gitalk/dist/gitalk.css'
import './style.css'
import '@/styles/atom-dark-syntax.css'

class Template extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    let scripts = document.querySelectorAll('[data-inline-script="pass-it"]')
    scripts.forEach((element) => {
      if (element.src === '') {
        window.eval(element.innerHTML)
      } else {
        const script = document.createElement('script')
        script.type = 'text/javascript'
        script.src = element.src
        script.onload = () => window.eval(element.innerHTML)
        document.body.appendChild(script)
      }
    })

    const gitalk = new Gitalk({
      clientID: 'de04802a3102524b19dc',
      clientSecret: 'd36e4f7aecf8fd5c303f0738e79f5e0b1b2bfb64',
      repo: 'sir0xb.github.io',
      owner: 'Sir0xb',
      admin: ['Sir0xb'],
      id: this.props.data.markdownRemark.frontmatter.path.substring(1),
      distractionFreeMode: false,
    })

    gitalk.render('gitalk-container')
  }

  render() {
    const post = this.props.data.markdownRemark

    return (
      <Layout categories={post.frontmatter.categories}>
        <SEO title={post.frontmatter.title} />
        <button
          className="go-back"
          onClick={() => {
            window.history.back()
          }}
        >
          Go back
        </button>
        <div className="blog-post">
          <h2 className="title">{post.frontmatter.title}</h2>
          <h6 className="information">
            Posted by {post.frontmatter.author} on {post.frontmatter.date}
          </h6>
          <div dangerouslySetInnerHTML={{ __html: post.html }}></div>
        </div>
        <div id="gitalk-container"></div>
      </Layout>
    )
  }
}

export const postQuery = graphql`
  query BlogxxxPostByPath($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
        date
        comments
        categories
        author
        tags
      }
    }
  }
`

export default Template
